<template>
    <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content"
        @beforeOpen="(event) => processParams(event.ref.params._rawValue)">
        <!-- Begin::Header -->
        <div class="modal__header">
            <span class="modal__title">
                Notice
            </span>
        </div>
        <!-- End::Header -->
        <!-- Begin::Body -->
        <div class="modal__content">
            <!-- Begin::Details section -->
            <div class="row">
                <div class="offset-2 col-8">
                    <h2 class="text-center text-uppercase mt-4 mb-8">Neoris Dashboard, Important reminder</h2>

                    <p class="lead">
                        <strong>Information and appointment inquiries from prospects are available during two days.</strong>
                        After this time, the prospect will no longer appear on your Dashboard.
                    </p>
                    <p class="lead">
                        Your prospects acceptance rate was {{acceptanceRateLastMonth}}% the previous month.<br />
                        You currently receive {{prospectsTransmissionRate}}% of the inquiries addressed to you.
                    </p>


                    <details>
                        <summary class="mb-8">More details</summary>
                        <p>
                            You agreed to accept requests sent to you within two working days.<br />
                            Afer this time, the prospect will no longer show up on your Dashboard and they will automatically receive the following email:
                        </p>
                        <p>
                            Hello,<br />
                            You sent a request to the clinic.<br />
                            The clinic is not currently taking new patients.<br />
                            We apologise for this inconvenience and invite you to send your request to another doctor on the site.<br />
                            Kind regards,<br />
                            The Neoris Team
                        </p>
                        <p>
                            Your prospects acceptance rate (Number of accepted inquiries ÷ Total number of inquiries) is measured each end of month.
                            If it is &lt; 80%, a decrease of 50% will automatically be applied to new prospects inquiries you receive the following month (M+1).
                        </p>
                        <p>
                            The next month (M+2):
                            <ul>
                                <li>If the acceptance rate is &gt; 80% at M+1, inquiries sent at M+2 will be increased back to 75% of all inquiries, and finally to 100% after 2 months.</li>
                                <li>However, if the acceptance rate stays &lt; 80%, prospects inquiries will be decreased of 50% again (which would lead to receiving 25% of all inquiries at M+2), and so on.</li>
                            </ul>
                        </p>
                        <p>
                            Unaccepted inquiries will be put on hold or addressed to other clinics.
                        </p>
                        <!-- <p>
                            RQ: on enverrait une demande sur 2, sur 3, sur 4 .... par ordre d'arrivée.
                        </p> -->
                    </details>

                    <div class="d-block">
                        <!-- <button class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm">OK</button> -->
                        <button class="btn btn-lg btn-primary me-2" @click="() => { seen(); close(); }">Ok</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="modal__close btn btn-icon btn-link" @click="() => { seen(); close(); }">
            <i class="bi bi-x-lg"></i>
        </button>
    </vue-final-modal>
</template>
  
<script>
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
const clinicId = JwtService.getUser().clinic.id;

import { useToast } from "vue-toastification";

export default {
    emits: ["confirm"],
    setup() {
        return {
            toast: useToast(),
        };
    },
    data() {
        return {
            acceptanceRateLastMonth: null,
            prospectsTransmissionRate: null,
        };
    },
    methods: {
        seen() {
            localStorage.setItem("terms-seen-in-session", true);
        },
        processParams(params) {
            console.log(params)

            // Last day of prev. month
            const to = new Date(); // current date
            to.setDate(1); // going to 1st of the month
            to.setHours(-1); // going to last hour before this date even started.
            
            const from = new Date(to);
            from.setDate(1); // going to 1st of the month

            const fromStr = `${from.toISOString().substring(0, 10)}T00:00:00`;
            const toStr = `${to.toISOString().substring(0, 10)}T23:59:59`;
            
			ApiService.get("clinics", clinicId).then(({ data }) => {
                this.prospectsTransmissionRate = data.prospectsTransmissionRate;
            });

            ApiService.query(`clinics/${clinicId}/prospects-acceptance-rate?from=${fromStr}&to=${toStr}`).then(({ data }) => {
                this.acceptanceRateLastMonth = data.acceptanceRate;
            });
        },
    },
};
</script>
  